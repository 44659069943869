import React from "react"
import { Footer } from "../components"

export const LayoutFull = ({ children }) => {
  return (
    <>
      <div>
        {children}
        <Footer />
      </div>
    </>
  )
}
